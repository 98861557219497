@import '@/style/colors';
@import 'src/style/fonts';

.login {
  display: flex;

  .header {
    border-bottom: 1px solid $color-white-secondary;
  }

  .flexBetween {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
  }

  .form {
    margin-bottom: 0;
    @media (max-width: 600px) {
      margin-bottom: 3rem;
    }
  }
}

.loginBtn {
  background: rgba(127, 78, 173, 0.15) !important;
  border-color: $color-purple-medium !important;
  color: $color-purple-medium !important;
  margin-bottom: 1rem !important;

  :hover {
    background: rgba(127, 78, 173, 0.2) !important;
  }
}

.loginLabel {
  color: $color-gray-dark-secondary;
  font-size: $font-size-12 !important;
  font-weight: normal;
  line-height: 20px;
}


.passIcon {
  float: right;
  margin-right: 9px;
  margin-top: -52px;
  position: relative;

  svg {
    color: #a3a4a4;
    height: 15px;
  }
}

.inputIcon {
  align-items: center;
  color: $color-gray-light;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 40px;
}

.signUp {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 3rem;
  margin-top: 2rem;

  a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: underline;
  }
  @media screen and (max-width: 810px) {
    font-size: 14px;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }

}

.loginMobileFooter {
  background: linear-gradient(0deg, #17171a, #17171a), $color-gray-dark-medium;
  border-radius: 10px 10px 0 0;
  display: none;
  min-height: 244px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.8rem;
  position: relative;
  width: 100vw;
  @media (max-width:600px) {
    display: block;
  }

  div {
    div {
      button {
        background-color: transparent !important;
      }

      ul {
        bottom: -1rem !important;
        left: -3rem !important;
        margin-top: 2rem;
        position: absolute;
        right: auto !important;
        @media (min-width:450px) {
          bottom: -3rem !important;
          left: -5rem !important;
        }

        li {
          background: $color-gray-dark-secondary;
          border-radius: 17px;
          height: 8px !important;
          width: 8px !important;

          &:global(.slick-active) {
            background-color: $color-purple-medium !important;
            width: 30px !important;
          }
        }
      }
    }
  }

  h2 {
    color: $color-white;
    font-size: $font-size-20;
    font-weight: 800;
  }

  p {
    color: $color-white-secondary;
    font-size: $font-size-14;
    font-weight: 300;
    line-height: 24px;
  }
}



@media (max-width:450px) {
  .login {
    &Left {
      padding: 2.5rem 15px;

      h1 {
        font-size: $font-size-24;
        font-weight: 800;
      }
    }
  }
}

.languagesSwitcher {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-top: 2.5rem;

  li {
    color: $color-blue;
    font-size: $font-size-12;

    margin-right: 10px;
    padding: 0 4px;

    :hover {
      text-decoration: underline;
    }
  }
}

.login {
  display: flex;


  input {
    background-color: #f9f9fb !important;
    border: 1px solid #e8e8e8 !important;
    font-weight: normal !important;
  }

  :global(.flag-dropdown) {
    background-color: #f9f9fb;
    border: 1px solid #ddd;

    .selected-flag {
      background-color: #f9f9fb !important;
    }
  }

  &Left {
    background-color: $color-white;
    min-height: 100vh;
    padding: 2.5rem 90px;
    width: 580px;

    .progressTrackerContainer {
      display: flex;
      justify-content: space-between;
      max-width: 85%;
      width: 500px;

      .progressSingleStep {
        display: flex;
        height: 30px;

        img {
          margin-right: 9px;
        }

        p {
          color: $color-gray-dark-secondary;
          margin: auto 0;
        }

        .googleColor {
          color: $color-pale-green;
        }
      }
    }

    .SignUp {
      img {
        margin-bottom: 2rem;

      }
    }

    &Header {
      margin-bottom: 2rem;
      position: relative;
    }

    h1 {
      font-size: $font-size-30;
      font-weight: 800;
    }

    p {
      color: $color-gray-dark-secondary;
      font-weight: 16px;
      font-weight: 400;
      margin-bottom: 1.5rem;
    }

    .flexBetween {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    .form {
      margin-bottom: 4rem;
      @media (max-width: 600px) {
        margin-bottom: 3rem;
      }
    }
  }

  .errorMessages {
    margin-bottom: 20px;
  }
  
  &Right {
    align-items: center;
    background: linear-gradient(0deg, #17171a, #17171a), $color-gray-dark-medium;
    border-radius: 10px 0 0 10px;
    color: $color-white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding-top: 5rem;
    position: relative;
    width: calc(100% - 580px);

    &Slider {
      position: absolute;
      z-index: 3;

      .loginBackground {
        margin: auto;
        position: relative;
        width: min-content;

        .loginGirl {
          bottom: 0;
          position: absolute;
          right: 10%;
        }
      }
    }

    &Text {
      height: 100vh;
      margin: auto;
      max-width: 650px;
      width: 100%;

      h2 {
        color: $color-white;
        font-size: $font-size-20;
        font-weight: 800;
      }

      p {
        color: $color-white-secondary;
        font-size: $font-size-14;
        font-weight: 300;
        line-height: 24px;
      }
    }

    .loginDash {
      height: 100%;
      padding-left: 4rem;
      position: relative;
      width: 100%;

      .backOrn1 {
        left: 65%;
        position: absolute;
        z-index: 1;
      }

      .backOrn2 {
        bottom: 50vh;
        left: 5%;
        position: absolute;
        z-index: 1;
      }

      .backOrn3 {
        bottom: -30px;
        left: 1%;
        position: absolute;
        z-index: 1;

        img {
          width: 90px;
        }
      }

      .backOrn4 {
        bottom: 0;
        position: absolute;
        right: 0;
        z-index: 1;

        img {
          width: 350px;
        }
      }

      .backOrn5 {
        position: absolute;
        right: -50px;
        top: 85px;
        transform: rotate(-30deg);
        z-index: 1;

        img {
          width: 130px;
        }
      }

      .backOrn6 {
        position: absolute;
        right: 75%;
        top: -210px;
        transform: rotate(300deg);
        z-index: 1;

        img {
          width: 260px;
        }
      }
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
}

.loginBtn {
  background-color: $color-purple-medium;
  border-color: $color-purple-medium !important;
  color: $color-white !important;
  font-weight: 400;
  margin-bottom: 1rem !important;
  transition: all 0.26s ease-in-out;
}

.loginLabel {
  color: $color-gray-dark-secondary;
  font-size: $font-size-12 !important;
  font-weight: normal;
  line-height: 20px;
}

.footer {
  small {
    color: $color-gray-light;
    font-size: $font-size-12;
    line-height: 20px;
  }
}

.inputOverrides {
  background: #936bda12 !important;
  border: 1px solid #9e85cc69;
  border-radius: 8px !important;
  color: #ffff;
  font-family: inter !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  height: 50px !important;
  line-height: 22.4px !important;
  text-align: left !important;


  &:hover,
  &:focus {
    background: #936bda12 !important;
    border: 1px solid #9e85cc69;
  }

  /* Increase specificity */
  :global(.ant-input.inputOverrides[disabled]) {
    background-color: rgba(147, 107, 218, 0.07) !important;
    border: 1px solid rgba(158, 133, 204, 0.41) !important;
    color: #ffff !important;
  }
}

.inputIcon {
  align-items: center;
  color: $color-gray-light;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 40px;
}

.emailInput {
  position: relative;

  :global(.ant-form-item) {
    min-width: 100%;
  }

  input {
    width: 100%;
  }

}



@media (max-width:450px) {
  .login {
    &Left {
      padding: 2.5rem 15px;

      .SignUp {
        img {
          margin-bottom: 2rem;
        }
      }

      h1 {
        font-size: $font-size-24;
        font-weight: 800;
      }
    }
  }
}


.ornaments {
  display: flex;

}

.ornament1 {
  background-color: $color-purple-medium;
  border-radius: 8px;
  height: 10px;
  margin-right: 0.5rem;
  width: 45px;

}

.ornament2 {
  background: $color-gray-dark-secondary;
  border-radius: 17px;
  height: 10px;
  margin-right: 0.5rem;
  width: 10px;
}

.logIn {
  color: $color-gray-light !important;
  text-align: center;
}

.sendEmail {
  color: $color-gray-light !important;
  text-align: left;
}

.startCreating {
  button {
    border: 1px solid $color-white-secondary;
    color: #323538;
    margin-bottom: 1.7rem;
  }
}

.selectInput {

  margin-top: 0.1em;
  width: 11.5em;


  :global(.ant-select-selector) {
    align-items: center;
    background: $color-gray-medium;
    border: 1px solid $color-gray-medium;
    border-radius: 4px !important;
    display: flex;
    font-size: $font-size-14;
    height: 40px !important;

    &:focus,
    &:active,
    &:hover {
      border-color: $color-purple-medium;
    }
  }

  :global(.ant-select-arrow) {
    color: $color-light-gray-secondary;
  }
}

.SignUpRegistered {
  margin-bottom: 4.75rem;
  margin-top: 1.3rem;
}

.phoneInput {
  :global(.form-control) {
    border: 1px solid #9e85cc69;
    border-radius: 8px !important;
    color: #fff;
    height: 41px;
    padding-left: 46px;
    width: 238px;

    &::after {
      background-color: #936bda12 !important;
      content: '';
      height: 5px;
      width: 7px;
    }
  }

  :global(.selected-flag) {
    // background-color: #936BDA12 !important;
    background-color: transparent !important;
    border: 0 !important;
    width: 10px;
  }

  :global(.flag-dropdown) {
    background-color: transparent !important;
    border-color: transparent;
    border-right: 0 !important;
    padding-left: 0.3rem;
  }

  :global(.arrow) {
    border-top: 4px solid #ffff !important;
    color: #fff !important;
    display: block;
    margin-left: 0.15rem;
  }
}

.tooltipError {
  background-color: transparent !important;

  :global(.ant-tooltip-content) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), $color-gray-dark-medium;
    border-radius: 8px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.08);
    padding: 2px 6px;
    width: max-content;

    :global(.ant-tooltip-inner) {
      div {
        color: $color-white !important;
        font-size: $font-size-12 !important;
        font-weight: 300;
        white-space: nowrap;

        strong {
          font-weight: 600;
        }

        span {
          color: $color-red !important;
          margin-right: 2px !important;
          width: max-content;
        }
      }
    }}
}

.languagesSwitcher {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style-type: none;
  margin-top: 5rem;

  li {
    color: $color-blue;
    font-size: $font-size-12;

    margin-right: 10px;
    padding: 0 4px;

    :hover {
      text-decoration: underline;
    }
  }
}

.signUpBg {
  align-items: center;
  // background: var(--dynamic-background);
  background: url('/img/registerBg/purpleImg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  // padding-top: 58px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
  padding-top: 58px;
  position: relative;
  text-align: center;
  width: 100%;
}

.bgBack {
  background-color: #17181b;
  left: 0;
  min-height: 100%;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.bgFront {
  background: linear-gradient(180deg, #17181b 11.25%, var(--brand-color) 78%, #17181b 100%);
  left: 0;
  min-height: 100%;
  opacity: 0.2;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

@media (max-width: 350px) {
  .logo {
    width: 300px !important;
  } 
}

@media (max-width: 320px) {
  .logo {
    width: 250px !important;
  } 
}

.descriptionContainer {
  font-family: GT Walsheim Pro;
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 56px;
  text-align: center;
  z-index: 3;
}

.descLineOne {
  color: #fff;
  @media screen and (max-width: 1200px) {
    font-size: 36px;
    line-height: 38px;
  }
  @media screen and (max-width: 1030px) {
    font-size: 32px;
    line-height: 34px;
  }
  @media screen and (max-width: 810px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
}

.descLineTwo {
  color: #936bda;
  @media screen and (max-width: 1600px) {
    font-size: 60px;
    line-height: 38px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 36px;
    line-height: 38px;
  }
  @media screen and (max-width: 1030px) {
    font-size: 32px;
    line-height: 34px;
    margin-left: -10px;
  }
  @media screen and (max-width: 810px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media screen and (max-width: 400px) {
    font-size: 17px;
    line-height: 28px;
  }
  @media screen and (max-width: 350px) {
    font-size: 15px;
    line-height: 28px;
  }
}

.descriptionWrapper {
  margin-top: 25px;

  @media screen and (max-width: 1200px) {
    margin-top: -8px;
  }
  @media screen and (max-width: 600px) {
    margin-top: -22px;
  }
}

.description {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;

  @media screen and (max-width: 1600px) {
    font-size: 23px;
    line-height: 31px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 25px;
  }
  @media screen and (max-width: 1030px) {
    font-size: 12px;
    line-height: 20px;
  }
  @media screen and (max-width: 600px) {
    font-size: 10px;
    line-height: 16px;
  }
  @media screen and (max-width: 400px) {
    font-size: 8px;
    line-height: 14px;
  }
  @media screen and (max-width: 350px) {
    font-size: 7px;
    line-height: 12px;
  }
}

.logo {
  @media screen and (max-width: 1030px) {
    margin-bottom: 20px !important;
  }
  @media screen and (max-width: 810px) {
    margin-bottom: 20px !important;
    width: 240px !important;
  }
  @media screen and (max-width: 600px) {
    width: 190px !important;
  }
}

.formTitle {
  color: #fff;
  font-family: inherit;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 47px;
  text-align: center;
  @media screen and (max-width: 810px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 20px !important;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
    margin-top: 20px !important;
    padding-inline: 10px;
  }
}

.label {
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  margin-bottom: 4px;
  opacity: 0.5;
  text-align: left;
}

.input {
  background: #936bda12 !important;
  border: 1px solid #9e85cc69;
  border-radius: 8px !important;
  color: #ffff;
  height: 50px !important;
  width: 100%;

  :global(.ant-input) {
    background: transparent !important;
    border: 1px solid #9e85cc69;
    color: #ffff;
  }

  &:hover,
  &:focus {
    background: #936bda12 !important;
    border: 1px solid #9e85cc69;
  }

  :global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled)):hover {
    background: #936bda12 !important;
    border: 1px solid #9e85cc69;
  }
}

.or {
  color: #fff;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  margin: 13px 0;
  opacity: 0.7;
  text-align: center;
}

.footerTxtContainer {
  margin-bottom: 3rem;
  margin-top: 3rem;
  z-index: 3;
}

.footerTxt {
  color: #fff;
  font-family: inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  @media screen and (max-width: 1200px) {
    font-size: 24px;
    line-height: 26px;
  }
  @media screen and (max-width: 1030px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media screen and (max-width: 810px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 16px;
  }
}

.stepTwoHeading {
  color: #fff;
  font-family: inter;
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 45px;
  margin-top: 24px;
  text-align: center;
}

.forgotPass {
  color: #ffff;
  text-align: start;
  text-decoration: underline;

  a {
    color: #ffff !important;
  }
}

.container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.text {
  color: #fff;
  font-family: inter;
  font-size: 80px;
  font-weight: 700;
  line-height: 80px;
  margin-bottom: 0 !important;
  text-align: center;
  transition: all 0.5s ease;
  @media screen and (max-width: 1600px) {
    font-size: 60px;
    line-height: 38px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 36px;
    line-height: 38px;
  }
  @media screen and (max-width: 1030px) {
    font-size: 32px;
    line-height: 34px;
  }
  @media screen and (max-width: 810px) {
    font-size: 28px;
    line-height: 30px;
  }
  @media screen and (max-width: 600px) {
    font-size: 20px;
    line-height: 28px;
  }
  @media screen and (max-width: 400px) {
    font-size: 17px;
    line-height: 28px;
  }
  @media screen and (max-width: 350px) {
    font-size: 15px;
    line-height: 28px;
  }
}

.console-underscore {
  animation: blink-animation 1s steps(5, start) infinite;
  display: inline-block;
  overflow: hidden;
  vertical-align: bottom;
}
@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.hidden {
  visibility: hidden;
}
