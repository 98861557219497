.errorPage {
  background-color: #24262a;
  display: grid;
  height: 100%;
  place-items: center;

  &Container {
    max-width: 330px;
    min-width: 330px;

    >img {
      margin-bottom: 30px;
    }

    >h1 {
      color: #fff;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }

    >p {
      color: #f2f2f5;
      font-size: 16px;
      font-weight: normal;
      line-height: 24px;
      margin-bottom: 30px;
      max-width: 290px;
    }

    &Actions {
      display: flex;
      justify-content: space-between;

      >button {
        height: 44px;
        width: 158px;
      }
    }
  }
}

.transparentBtn {
  padding: 7px 12px 7px 0 !important;
  text-align: left !important;
}
